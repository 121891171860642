import React from 'react'
import { graphql, Link } from 'gatsby'
import { Container } from '@components/common'
import Icon from '@mrs-ui/Icon'
import './blog.scss'
import { BlogSidebar } from '@components/Blog'
import Head from '@components/Head'

type WpBlogPost = {
  title: string
  id: string
  slug: string
  content: any
  date: string
  featuredImage: { node: { sourceUrl: string } }
  excerpt: string
}
type BlogPost = {
  wpBlogPost: WpBlogPost
}

const BlogPost = ({ data }) => {
  const { wpBlogPost }: BlogPost = data
  return (
    <>
      <Head
        title={wpBlogPost.title}
        description={wpBlogPost.excerpt}
        ogImage={wpBlogPost.featuredImage.node.sourceUrl}
      />
      <Container>
        <div className="flex flex-wrap py-8 blog-post">
          <article className="md:w-2/3 md:pr-24 w-full">
            <header>
              <div className="mb-4 flex items-center text-small">
                <Link to="/" className="hover:underline">
                  Home
                </Link>
                <Icon name="ChevronRight" className="h-4 w-4 mx-1 flex" />
                <Link to="/blog" className="hover:underline">
                  Blog
                </Link>
              </div>

              <h1 className="mb-2">{wpBlogPost.title}</h1>
              <div className="mb-4 text-shade600 text-small">
                By MRS on {wpBlogPost.date}
              </div>
            </header>
            <div
              dangerouslySetInnerHTML={{
                __html: wpBlogPost.content,
              }}
              className="pb-12"
            />
          </article>
          <aside className="md:w-1/3 w-full">
            <div className="sticky" style={{ top: '100px' }}>
              <BlogSidebar />
            </div>
          </aside>
        </div>
      </Container>
    </>
  )
}
export default BlogPost

export const data = graphql`
  query ($slug: String!) {
    wpBlogPost(slug: { eq: $slug }) {
      id
      slug
      title
      content
      excerpt
      date(formatString: "dddd, MMMM D YYYY")
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
  }
`
